import { Dispatch, SetStateAction } from 'react';
import { useRouter } from 'next/router';

import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { onPushEvent } from '@/services/lib/gtm';
import { useTranslation } from '@/hooks/translations';
import { useUserContext } from '@/contexts/user';
import { useRestaurantContext } from '@/contexts/restaurant';
import { BrandScheduledReportRole } from '@/constants/roleRestrictions';
import TimeDrawer from '@/components/TimeRange/Drawer';
import { CustomButton, PrimaryButton } from '@/components/common';
import { type ITimeRangeForm, TimeRangeTypes } from '@/components/TimeRange/Form/types';
import { LegacyDashboardTypes } from '@/views/Dashboard';
import CachedRounded from '@mui/icons-material/CachedRounded';
import ToggleButtonGroup from './ToggleButton';

const Filters = ({
    setTimeRange,
    legacyDashboard,
    setLegacyDashboard,
    setRefreshBoolean,
}: {
    setTimeRange: Dispatch<SetStateAction<ITimeRangeForm>>;
    legacyDashboard: LegacyDashboardTypes;
    setLegacyDashboard: Dispatch<SetStateAction<LegacyDashboardTypes>>;
    setRefreshBoolean: Dispatch<SetStateAction<boolean>>;
}) => {
    const { push } = useRouter();
    const { t } = useTranslation('common');
    const { user } = useUserContext();
    const { restaurant, selectedProductType, setSelectedProductType, selectedQrGroup, setSelectedQrGroup } =
        useRestaurantContext();

    const productTypes = [
        { option: t('Qlub QR'), value: 'qlub' },
        { option: t('QSR'), value: 'qsr' },
        { option: t('Qlub Terminal'), value: 'softpos' },
    ];

    const transactionDashboardEnabled = restaurant?.config?.transactionDashboardEnabled || false;
    const isQrGroupVisible =
        !BrandScheduledReportRole.includes(user?.userData?.role) && legacyDashboard === LegacyDashboardTypes.ORDER;

    const todayDate = new Date();
    const minDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 90);

    const setSelectedGroupHandler = (group: string) => {
        if (group === 'All') {
            setSelectedQrGroup(null);
            return;
        }
        setSelectedQrGroup(group);
    };

    const refresh = () => {
        setRefreshBoolean((o) => !o);
    };

    return (
        <>
            {transactionDashboardEnabled ? (
                <Grid
                    item
                    sm={(12 / 5) * 2}
                    md={(12 / 5) * 2}
                    lg={(12 / 5) * 2}
                    xl={(12 / 5) * 2}
                    sx={{
                        display: 'flex',
                        flexDirection: { xl: 'row', lg: 'row', md: 'row', sm: 'column', xs: 'column' },
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <ToggleButtonGroup
                        onChange={(val) => {
                            setLegacyDashboard(val);
                        }}
                        options={[
                            {
                                tooltip: t(
                                    'The Orders Tab will display data based on the order date. This tab is useful for matching orders with POS systems.',
                                ),
                                value: LegacyDashboardTypes.ORDER,
                                text: t('By Order Date'),
                            },
                            {
                                tooltip: t(
                                    'The Transactions Tab will display data based on the transaction date. This tab is useful for reconciliation and end-of-day Z reading.',
                                ),
                                value: LegacyDashboardTypes.TRANSACTIONS,
                                text: t('By Transaction Date'),
                            },
                        ]}
                        size="small"
                        value={legacyDashboard}
                    />
                </Grid>
            ) : (
                <Grid
                    item
                    sm={(12 / 5) * 2}
                    md={(12 / 5) * 2}
                    lg={(12 / 5) * 2}
                    xl={(12 / 5) * 2}
                    sx={{
                        display: 'flex',
                        flexDirection: { xl: 'row', lg: 'row', md: 'row', sm: 'column', xs: 'column' },
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}
                />
            )}
            <Grid item xs={12} sm={(12 / 5) * 3} md={(12 / 5) * 3} lg={(12 / 5) * 3} xl={(12 / 5) * 3}>
                <Grid container spacing={2}>
                    {isQrGroupVisible ? (
                        <>
                            <Grid item xs={6} sm={4}>
                                <Select
                                    size="small"
                                    value={selectedQrGroup || 'All'}
                                    onChange={(e) => {
                                        setSelectedGroupHandler(e.target.value || '');
                                    }}
                                    sx={{
                                        marginRight: '10px',
                                        borderRadius: '8px',
                                        width: '100%',
                                        textAlign: 'left',
                                        color: '#7d00d4',
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#7d00d480',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#7d00d480',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#7d00d480',
                                        },
                                        '.MuiSvgIcon-root ': {
                                            fill: '#7d00d4 !important',
                                        },
                                    }}
                                >
                                    <MenuItem value={'All'}>{t('All QR Groups')}</MenuItem>
                                    {restaurant?.qr_groups?.map((group) => (
                                        <MenuItem value={group.id}>{group.name}</MenuItem>
                                    ))}
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
                                    >
                                        <PrimaryButton
                                            sx={{
                                                marginTop: '10px',
                                                borderRadius: '8px',
                                                height: '40px',
                                                textTransform: 'none',
                                            }}
                                            onClick={() => {
                                                onPushEvent('vendor_qr-groups_create-icon-button-selected');
                                                push('/qr_code');
                                            }}
                                            text={t('Add a Qr Group')}
                                            size="medium"
                                        />
                                    </Grid>
                                </Select>
                            </Grid>
                            <Grid item xs={isQrGroupVisible ? 6 : 12} sm={4}>
                                <Select
                                    size="small"
                                    value={selectedProductType || 'All'}
                                    onChange={(e) => {
                                        const val = e.target.value;
                                        if (val === 'All') setSelectedProductType(null);
                                        else setSelectedProductType(val);
                                    }}
                                    sx={{
                                        marginRight: '10px',
                                        borderRadius: '8px',
                                        width: '100%',
                                        textAlign: 'left',
                                        color: '#7d00d4',
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#7d00d480',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#7d00d480',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#7d00d480',
                                        },
                                        '.MuiSvgIcon-root ': {
                                            fill: '#7d00d4 !important',
                                        },
                                    }}
                                >
                                    <MenuItem value={'All'}>{t('All Products')}</MenuItem>
                                    {productTypes.map(({ option, value }) => (
                                        <MenuItem key={value} value={value}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={6} sm={4} />
                            <Grid item xs={12} sm={4}>
                                <CustomButton
                                    onClick={() => {
                                        refresh();
                                        onPushEvent('user_click_on_refresh');
                                    }}
                                    endIcon={<CachedRounded />}
                                    size="large"
                                    sx={{ color: 'primary.main', width: '100%', textTransform: 'none' }}
                                >
                                    {t('Refresh')}
                                </CustomButton>
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12} sm={4}>
                        <TimeDrawer
                            onConfirm={(val) => {
                                onPushEvent(`user_use_filter_date_${val.type}`);
                                setTimeRange(val);
                            }}
                            options={[
                                TimeRangeTypes.TODAY,
                                TimeRangeTypes.YESTERDAY,
                                TimeRangeTypes.WEEK,
                                TimeRangeTypes.LAST_7_DAYS,
                                TimeRangeTypes.MONTH,
                                TimeRangeTypes.LAST_30_DAYS,
                            ]}
                            minDate={minDate}
                            timeSelectEnabled
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Filters;
