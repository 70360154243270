import { useTranslation } from '@/hooks/translations';
import { useLanguageQuery } from 'next-export-i18n';
import DataTable from 'react-data-table-component';
import useDataTable from '@/hooks/useDataTable';
import { Box, Grid, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import DashboardService from '@/services/dashboard';
import { qlubLoginModeKey } from '@/config/axios';
import { useUserContext } from '@/contexts/user';
import { onPushEvent } from '@/services/lib/gtm';
import { Section } from '../common';

interface IBestsellingRow {
    rank: number;
    title: string;
    quantity: number;
    average_price: number;
}

const BestsellingItems = () => {
    const dashboardService = DashboardService.getInstance();
    const { t } = useTranslation('common');
    const { user } = useUserContext();
    const [query] = useLanguageQuery();
    const lang = query ? query.lang : 'en';
    const [period, setPeriod] = useState('m');
    const role = localStorage.getItem(qlubLoginModeKey);

    const { loading, list, getData } = useDataTable<IBestsellingRow, ''>({
        withSearch: true,
        dataFetcher: async () => {
            try {
                if (role !== null && user) {
                    const res = await dashboardService.getBestSellingItems(user.restaurantId, period);
                    return { list: res.data.bestSellingItems, total: res.data.bestSellingItems.length };
                }
                return { list: [], total: 0 };
            } catch (error) {
                return {
                    list: [],
                    total: 0,
                };
            }
        },
    });

    const columns = useMemo(
        () => [
            {
                name: t('Rank'),
                selector: (row: IBestsellingRow) => row.rank,
            },
            {
                name: t('Product Name'),
                selector: (row: IBestsellingRow) => row.title,
            },
            {
                name: t('Item Sold'),
                selector: (row: IBestsellingRow) => row.quantity,
            },
            {
                name: t('Average Price'),
                selector: (row: IBestsellingRow) => row.average_price,
            },
        ],
        [lang],
    );

    const handlePeriodChange = (event: SelectChangeEvent) => {
        onPushEvent(`user_change_period_on_popular_items_to_${event.target.value || ''}`);
        setPeriod(event.target.value as string);
    };

    useEffect(() => {
        if (!user) {
            return;
        }
        getData();
    }, [user, period]);

    return (
        <Grid item xs={12}>
            <Section sx={{ borderColor: '#E5E5E5' }}>
                <DataTable
                    customStyles={{
                        headCells: {
                            style: {
                                width: 'unset',
                            },
                        },
                        table: {
                            style: {
                                width: '%100',
                            },
                        },
                        rows: {
                            style: {
                                borderBottom: 'none !important',
                            },
                        },
                    }}
                    actions={
                        <Box>
                            <Select value={period} onChange={handlePeriodChange} size="small">
                                <MenuItem value="w">{t('weekly')}</MenuItem>
                                <MenuItem value="m">{t('monthly')}</MenuItem>
                                {/* <MenuItem value="s">{t('semi-annuarly')}</MenuItem> */}
                            </Select>
                        </Box>
                    }
                    title={<Typography variant="h6">{t('Popular Items')}</Typography>}
                    striped
                    columns={columns}
                    data={list}
                    progressPending={loading}
                    noDataComponent={t('There are no records to display')}
                />
            </Section>
        </Grid>
    );
};

export default BestsellingItems;
